<style scoped>
.autocomplete {
  min-height: 100px;
  max-height: 100px;
  overflow: hidden;
  overflow-y: scroll
}

.selected {
  background-color: lightslategrey;
}
</style>

<template>
  <div v-if="items.length" class="autocomplete">
    <div v-for="(item, index) in items" :key="index" :class="{selected: index == position}">
      {{item}}
    </div>
</div>
</template>

<script>

export default {
  name: 'AutocompleteDisplay',
  inject: [
  ],
  props: {
    items: Array,
    position: Number
  },
  data: function () {
    return {
    }
  },
  watch: {
    items() {
    },
    position() {
    }
  },
  created: function () {

  },
  methods: {
    
  }
}
</script>
