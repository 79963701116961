<style scoped>
.badge {
  position: relative;
  margin: 4px;
}

img {
  width: 64px;
  height: 64px;
}

.big {
  width: 128px;
  height: 128px;  
}

.badge i {
  z-index: 1;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  opacity: 0.8;
  filter: drop-shadow(1px 1px black);
}

td {
  padding: 5px;
  min-width: 150px;
}

@media only screen and (min-width: 700px) {
  .big {
    width: 256px;
    height: 256px;
  }
}
</style>

<template>
  <span class="badge">
    <router-link :to="{name: 'badge', params: {id: badge.id}}">
      <img :src="badge.icon_base64" :title="badge.description" v-bind:class="mode">
    </router-link>
    <span v-if="manager"><i class="fa fa-solid fa-cog"></i></span>
  </span>
</template>

<script>
export default {
  name: 'BadgeDisplay',
  inject: [
    'authenticated'
  ],
  props: {
    badge: undefined,
    manager: Boolean,
    mode: String
  }
}
</script>
