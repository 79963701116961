
<style>
  @import url('../node_modules/@fontsource/roboto/300.css');
  @import url('../node_modules/@fontsource/roboto/400.css');
  @import url('../node_modules/@fontsource/roboto/500.css');

  /* https://fontawesome.com/v4/icons/ */
  @import url('../node_modules/font-awesome/css/font-awesome.min.css');

  html {
    overflow-y: overlay;
  }

  html, body {
    font-family: roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
  }

  a {
    color: #2b3c5b;
    text-decoration: none;
  }

  header {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 40px;

    align-items: center;
  }

  .header_expand {
    width: 100%;
  }

  .header_item {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.06em;

    margin-left: 20px;
  }

  a:hover {
    text-decoration: underline;
  }

  main {
    flex: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  #profile_image {
    width: 32px;
    border-radius: 16px;
    vertical-align: middle;
  }

  footer {
    flex: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 20px;
  }

  @media only screen and (min-width: 700px) {
    header {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 40px;
    }

    main {
      padding-left: 40px;
      padding-right: 40px;
    }

    footer {
      padding-left: 40px;
      padding-right: 40px;
    }
  }


</style>

<template>
  <header>
    <div>
      <router-link to="/">
        <img width="140" height="40" src="./assets/logo.svg">
      </router-link>
    </div>
    <div class="header_expand"></div>
    <div v-if="authenticated" class="header_item">
      <router-link to="/badges">Badges</router-link>
    </div>
    <div class="header_item">
      <router-link to="/"><img id="profile_image" :src="profile_image" referrerpolicy="no-referrer"></router-link>
    </div>
  </header>
  <main>
    <div v-if="show_login">
      Please login to contiue <button id="signin" v-on:click="signin()">Login</button>
    </div>
    <router-view></router-view>
  </main>
  <footer>
    <div v-if="authenticated">
      <button id="signout" v-on:click="signout()">Logout</button>
    </div>
    <div id="error" class="error"></div>
  </footer>
</template>

<script>
import { setError } from "@/error"
import { computed } from "vue";
import { initializeApp } from 'firebase/app'
import { getAuth, OAuthProvider, signInWithCredential, signOut } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBI4-nvqlha-Lg1aqM4iVqwyDGTUK1HFO0",
  authDomain: "badges-346821.firebaseapp.com",
  projectId: "badges-346821",
  storageBucket: "badges-346821.appspot.com",
  messagingSenderId: "709540940283",
  appId: "1:709540940283:web:d6524f80192f1b499da35e"
};

var GAPI_CONFIG = {
  client_id: "709540940283-lku8sn8a1ou3719lbrkmijpv24mcvvh1.apps.googleusercontent.com",
  scopes: [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/directory.readonly",
    "https://www.googleapis.com/auth/admin.directory.user.readonly"
  ],
  redirect_uri: window.location.origin + "/"
}

export default {
  name: 'App',
  data: function () {
    return {
      profile_image: undefined,
      profile_email: undefined,
      profile_name: undefined,
      authenticated: false,
      show_login: false,
      token: String
    }
  },
  provide() {
    return {
      authenticated: computed(() => this.authenticated),
      profile_email: computed(() => this.profile_email),
      firebase_app: computed(() => this.firebase_app),
      firebase_db: computed(() => this.firebase_db)
    }
  },
  created: function() {
    console.log('app created')

    // Initialize firebase.
    this.firebase_app = initializeApp(firebaseConfig)
    this.firebase_db = getFirestore(this.firebase_app)

    this.authenticate();
  },
  methods: {

    authenticate: function () {
      var access_token = undefined;

      // Detect/parse redirect from OAuth2 request.
      // This should trigger on a redirect back from clicking the Login button.
      const oauthCallbackRegex = /.*state=(.*)&access_token=([^&]+)&.*expires_in=([0-9]+).*/;
      const oauthData = window.location.hash.match(oauthCallbackRegex);
      if (oauthData) {
        // Save token.
        localStorage['oauth_access_token'] = oauthData[2];
        localStorage['oauth_timeout'] = (new Date()).getTime() + parseInt(oauthData[3]) * 1000

        access_token = localStorage.getItem('oauth_access_token');

        // Redirect back to previous state.
        this.$router.push(oauthData[1])
      } else {
        // Not a redirect from OAuth2.
        // Check if we've logged in previously.
        var oauth_timeout = localStorage.getItem('oauth_timeout');
        if (oauth_timeout) {
          // Check if our login token is stale.
          oauth_timeout = parseInt(oauth_timeout)
          if ((new Date()).getTime() > oauth_timeout) {
            // We've logged in previously, but the token is stale.
            // Clear the token and attempt an automatic signin.
            localStorage.removeItem('oauth_access_token');
            localStorage.removeItem('oauth_timeout');

            this.signin();
            return;
          } else {
            // We've logged in previously, and we have a fresh token.
            access_token = localStorage.getItem('oauth_access_token');
          }
        }
      }

      // If we have an access token, we will assume we are authenticated.
      if (access_token) {
        var provider = new OAuthProvider("google.com");
        const credential = provider.credential({
          accessToken: access_token
        });
        
        signInWithCredential(getAuth(), credential).then(result => {
          this.profile_email = result.user.email;
          this.profile_image = result.user.photoURL;
          this.profile_name = result.user.displayName;

          console.log(this.profile_name)

          // Publish that we are authenticated.
          this.authenticated = true;
          this.show_login = false;
        }, error => {
          setError(error);
          this.authenticated = false;
        });

      } else {
        this.authenticated = false;
        this.show_login = true;
      }
    },

    signin: function() {
      // Create a hidden form that redirects the user to the Google signin system.
      var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

      // Create element to open OAuth 2.0 endpoint in new window.
      var form = document.createElement('form');
      form.setAttribute('method', 'GET');
      form.setAttribute('action', oauth2Endpoint)

      // Parameters to pass to OAuth 2.0 endpoint.
      var params = {'client_id': GAPI_CONFIG.client_id,
                    'redirect_uri': GAPI_CONFIG.redirect_uri,
                    'scope': GAPI_CONFIG.scopes.join(" "),
                    'state': window.location.pathname,
                    'response_type': 'token'}

      // Add form parameters as hidden input values.
      for (var p in params) {
        var input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', p)
        input.setAttribute('value', params[p])
        form.appendChild(input)
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      // This will leave the page.
      document.body.appendChild(form)
      form.submit()
    },

    signout: function() {
      console.log("signing out");
      signOut(getAuth()).then(() => {
        console.log("firebase signout complete");
      }).catch((error) => {
        console.log(error);
      });

      localStorage.removeItem('oauth_access_token');
      localStorage.removeItem('oauth_timeout');

      this.token = undefined;
      this.profile_image = undefined;
      this.authenticated = false;
    }
  }
}
</script>
