<style scoped>
td {
  padding: 5px;
}

@media only screen and (min-width: 700px) {
  td {
    min-width: 150px;
  }
}

.profile_icon {
  padding-left: 5px;
  font-size: 28px;
}

.profile_icon a {
  color: #2b3c5b;
}
</style>

<template>
  <div v-if="authenticated" class="home">
    <div>
      Start date: <input type="text" v-model="start_date"/>
      End date: <input type="text" v-model="end_date"/>
    </div>
    <br>
    <span v-for="person in people" :key="person" class="person">
      <PersonDisplay :email="person"/>
    </span>
  </div>
</template>

<script>
import { getPeopleBetweenStartDates } from "@/google"
import PersonDisplay from '@/components/PersonDisplay.vue'

export default {
  name: 'PersonView',
  inject: [
    'authenticated',
    'firebase_db'
  ],
  components: {
    PersonDisplay
  },
  data: function () {
    return {
      people: undefined,
      start_date: "2017",
      end_date: "2030"
    }
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.load()
      }
    },
    start_date() {
      this.load()
    },
    end_date() {
      this.load()
    }
  },
  created: function () {
    if (this.authenticated) {
      console.log('authenticated')
      this.load()
    }
    this.$watch(
      () => this.$route.params, () => {
        this.load()
      }
    )
  },
  methods: {
    load: async function () {
      var start_date = Date.parse(this.start_date + "T00:00:00")
      var end_date = Date.parse(this.end_date + "T00:00:00")
      this.people = await getPeopleBetweenStartDates(localStorage['oauth_access_token'], start_date, end_date)
    }
  }
}
</script>
