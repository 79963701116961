<style scoped>
.fa-plus-circle {
  font-size: 14px;
}

.click {
  cursor: pointer;
}

.validation {
  color: red;
  padding: 2px;
}
</style>

<template>
  <span v-on:click="activate" class="click">
    <i class="fa fa-plus-circle"></i>
    {{message}}
  </span>
  <div v-if="popup" class="popup">
    <input type="text" v-model="name"
      @input="nameChange"
      @keydown.enter="submit"
      @keydown.down="nameArrowDown"
      @keydown.up="nameArrowUp"/>
    <button v-on:click="submit">Submit</button>
    <span v-if="update">
      <i class="fa fa-solid fa-cog fa-spin"></i>
    </span>
    <div class="validation" v-if="name_validation">{{name_validation}}</div>
    <AutocompleteDisplay :items="names" :position="namepos"/>
    
  </div>
</template>

<script>
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getPerson, loadPeople, searchPerson } from "@/google"
import AutocompleteDisplay from '@/components/AutocompleteDisplay.vue'
import { setError } from "@/error"

export default {
  name: 'AddPerson',
  inject: [
    'authenticated',
    'firebase_db'
  ],
  props: {
    type: String,
    badge: String,
    message: String
  },
  components: {
    AutocompleteDisplay
  },
  data: function () {
    return {
      name: undefined,
      popup: false,
      names: [],
      namepos: -1,
      name_validation: undefined,
      update: false
    }
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.load()
      }
    }
  },
  created: function () {
    if (this.authenticated) {
      this.load()
    }
  },
  methods: {
    load: async function () {
      await loadPeople(localStorage['oauth_access_token'])
    },
    activate: function () {
      this.popup = !this.popup
    },
    validate: async function() {
      var person = await getPerson(localStorage['oauth_access_token'], this.name)

      if (!person) {
        this.name_validation = "'" + this.name + "' is not a valid username (use full email address)"
      } else {
        this.name_validation = undefined
      }
    },
    submit: async function() {
      await this.validate()

      if (this.name_validation) {
        return
      }

      this.update = true
      var updateRecord = {}
      updateRecord[this.type] = arrayUnion(this.name)

      updateDoc(doc(this.firebase_db, "badges", this.badge), updateRecord).then(() => {
        this.update = false
        this.$router.go('/badge/' + this.badge)
      }).catch((error) => {
        setError(error)
      })
    },
    nameChange: async function() {  
      await searchPerson(localStorage['oauth_access_token'], this.name).then((names) => {
        this.names = names
        this.namepos = -1
      })
      await this.validate()
    },
    nameArrowDown: function() {
      this.namepos++
      this.namePosUpdate()
    },
    nameArrowUp: function() {
      this.namepos--
      this.namePosUpdate()
    },
    namePosUpdate: function() {
      if (this.namepos >= this.names.length) {
        this.namepos = this.names.length - 1
      } else if (this.namepos < 0) {
        this.namepos = 0
      }

      this.name = this.names[this.namepos]
      this.name_validation = undefined
    }
  }
}
</script>
