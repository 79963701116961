<style scoped>
.tag {
  display: inline-flex;
  align-items: center;
}

img {
  height: 64px;
  width: 64px;
  border-radius: 32px;
  margin: 3px;
}

.big {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin: 0px;
}

.minitag {
  height: 32px;
  width: 32px;
}
</style>

<template>
  <span :v-if="authenticated">
    <router-link :to="{name: 'person', params: {email: email}}">
      <div class="tag">
        <div>
          <img :src="photo" :title="name" referrerpolicy="no-referrer" v-bind:class="mode">
        </div>
        <div v-if="mode == 'minitag'">{{name}}</div>
      </div>
    </router-link>
  </span>
</template>

<script>
import { getPerson } from "@/google"

export default {
  name: 'PersonDisplay',
  inject: [
    'authenticated',
    'firebase_db'
  ],
  props: {
    email: String,
    mode: String
  },
  data: function () {
    return {
      photo: require("../assets/nophoto.png"),
      name: undefined
    }
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.load()
      }
    },
    email() {
      if (this.authenticated) {
        this.load()
      }
    }
  },
  created: function () {
    if (this.authenticated) {
      this.load()
    }
  },
  methods: {
    load: async function () {
      var person = await getPerson(localStorage['oauth_access_token'], this.email)
      if (person === undefined) {
        this.photo = require("../assets/nophoto.png")
        this.name = this.email
        this.resourceName = ""
      } else {
        this.name = person.name
        this.resourceName = person.resourceName

        if (person.photoUrl === undefined) {
          this.photo = require("../assets/nophoto.png")
        } else {
          this.photo = person.photoUrl
        }
      }
    }
  }
}
</script>
