<style scoped>
.validation {
  color: red;
}
.icon-preview {
  width: 200px;
  height: 200px;
}
</style>

<template>
  <div>
    <div v-if="icon_preview"><img class="icon-preview" :src="icon_preview"></div>
    <input accept="image/*" type='file' @change="iconChange">
    <div class="validation">{{icon_validation}}</div>
  </div>
</template>

<script>

export default {
  name: 'IconUpload',
  inject: [
  ],
  props: {
  },
  components: {
  },
  data: function () {
    return {
      icon_preview: undefined,
      icon_data: undefined,
      icon_validation: undefined
    }
  },
  watch: {
  },
  created: function () {
  },
  methods: {
    load: async function () {
    },
    iconChange: function(value) {
      const [file] = value.target.files
      if (file) {
        this.icon_preview = URL.createObjectURL(file)

        var img = new Image()
        img.src = this.icon_preview
        img.onload = () => {
          if (img.width > 256) {
            this.icon_data = undefined
            this.icon_validation = 'Icon must not be larger than 256x256 pixels'
            return
          }
          if (img.width != img.height) {
            this.icon_data = undefined
            this.icon_validation = 'Icon must be square'
            return
          }
          // Create canvas
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          // Set width and height
          canvas.width = img.width;
          canvas.height = img.height;
          // Draw the image
          ctx.drawImage(img, 0, 0);
          this.icon_data = canvas.toDataURL('image/png')
          this.icon_validation = undefined

          this.$emit('update', this.icon_data)
        }
      }
    },
  }
}
</script>
