
async function googleApiGet(token, url = '') {

  // Default options are marked with *
  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    },
    redirect: 'follow', // manual, *follow, error
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

async function loadPeople(token) {
  // If we already have people records, and they aren't that old, use the existing records.
  if (localStorage['people']) {
    if (localStorage['peopleTime']) {
      var now = (new Date()).getTime();
      var delta = now - localStorage['peopleTime'];
      var seconds = delta / 1000;
      var minutes = seconds / 60;
      var hours = minutes / 60;

      if (hours < 24) {
        return;
      }
    }
  }

  // Get list of all people from the directory.
  var nextPageToken = undefined;
  var people = {};

  // Hit the service (max pageSize is 1000) until we've read everyone.
  do {
    var url = 'https://admin.googleapis.com/admin/directory/v1/users?'
      + 'viewType=domain_public&'
      + 'projection=full&'
      + 'customer=my_customer&'
      + 'pageToken=' + (nextPageToken ? nextPageToken : "");
    await googleApiGet(token, url).then(data => {
      
      if (data.error) {
        throw "Could not read directory: " + data.error.code + " " + data.error.message;
      }

      console.log(data)

      // Add people to people array.
      for (const i in data.users) {
        var user = data.users[i];

        // Check this user has a valid name.
        if (user.name === undefined) {
          continue;
        }

        // Check this user has a valid name.
        if (user.name.fullName === undefined) {
          continue;
        }

        // Check this user has a valid email address.
        if (user.primaryEmail === undefined) {
          continue;
        }

        var id = user.id;
        var name = user.name.fullName;
        var email = user.primaryEmail;

        // Exclude service accounts.
        if (email.includes('.service')) {
          continue;
        }
        
        // Exclude DER accounts.
        if (email.includes('.der')) {
          continue;
        }

        // Test accounts typically have a hyphen; exclude these.
        if (email.includes('-')) {
          continue;
        }

        people[email] = {
          "id": id,
          "name": name
        };

        console.log(user);

        try {
          var photoUrl = user.thumbnailPhotoUrl;
          people[email].photoUrl = photoUrl;
        } catch (error) {
          console.log(email + " has no photo");
        }

        try {
          var phone = user.phones[0].value;
          people[email].phone = phone;
        } catch (error) {
          console.log(email + " has no phone number");
        }

        try {
          var start_date = user.customSchemas.Employee_Data.Start_Date;
          people[email].start_date = start_date;
        } catch (error) {
          console.log(email + " has no start_date");
        }

        try {
          var employee_id = user.externalIds[0].value;
          people[email].employee_id = employee_id;
        } catch (error) {
          console.log(email + " has no employee_id");
        }

        try {
          var manager = user.relations[0].value;
          people[email].manager = manager;
        } catch (error) {
          console.log(email + " has no manager");
        }
      }

      // Advance to next page token.
      nextPageToken = data.nextPageToken;
    });
  } while (nextPageToken != undefined);

  // Write email addresses to localStorage, along with time it was fetched.
  localStorage['peopleTime'] = (new Date()).getTime();
  localStorage['people'] = JSON.stringify(people);
}

async function getPerson(token, email) {
  if (!token) {
    return undefined
  }
  if (!email) {
    return undefined
  }
  
  await loadPeople(token)

  var people = JSON.parse(localStorage['people'])
  return people[email]
}

async function searchPerson(token, partial) {
  await loadPeople(token)

  var people = JSON.parse(localStorage['people'])

  var results = []
  for (var email in people) {
    var person = people[email]

    if (email.includes(partial)) {
      results.push(email)
    } else if (person.name.includes(partial)) {
      results.push(email)
    } else if (partial == "") {
      results.push(email)
    }
  }

  results.sort()
  return results
}

function getPeopleCached() {
  return JSON.parse(localStorage['people']);
}

async function getPeopleStartDateOnOrBefore(token, date) {
  await loadPeople(token)

  var people = JSON.parse(localStorage['people'])

  var results = []
  for (var email in people) {
    var person = people[email]

    var person_date = Date.parse(person.start_date + "T00:00:00")

    if (isNaN(person_date)) {
      continue;
    }

    if (person_date <= date) {
      results.push(email)
    }
  }

  results.sort()
  return results
}

async function getPeopleBetweenStartDates(token, start_date, end_date) {
  await loadPeople(token)

  var people = JSON.parse(localStorage['people'])

  var results = []
  for (var email in people) {
    var person = people[email]

    var person_date = Date.parse(person.start_date + "T00:00:00")

    if (isNaN(person_date)) {
      continue;
    }

    if (person_date <= end_date && person_date >= start_date) {
      results.push(email)
    }
  }

  results.sort()
  return results
}

export { googleApiGet, getPerson, loadPeople, getPeopleCached, searchPerson, getPeopleStartDateOnOrBefore, getPeopleBetweenStartDates }
