<style scoped>
td {
  padding: 5px;
}

@media only screen and (min-width: 700px) {
  td {
    min-width: 150px;
  }
}

.profile_icon {
  padding-left: 5px;
  font-size: 28px;
}

.profile_icon a {
  color: #2b3c5b;
}
</style>

<template>
  <div v-if="authenticated" class="home">
    <table>
      <tr>
        <td align="right"></td>
        <td>
          <PersonDisplay :email="person_email" mode="big"/>
          <span class="profile_icon">
            <a v-if="person_google_profile" :href="person_google_profile"><i class="fa fa-google" aria-hidden="true"></i></a>
          </span>
          <span class="profile_icon">
            <a v-if="person_github_profile" :href="person_github_profile"><i class="fa fa-github" aria-hidden="true"></i></a>
          </span>
          <h1 v-if="this.person">{{this.person.name}}</h1>
        </td>
      </tr>
      <tr>
        <td align="right">Email</td>
        <td>{{person_email}}</td>
      </tr>
      <tr v-if="this.person && this.person.phone">
        <td align="right">Phone</td>
        <td>{{this.person.phone}}</td>
      </tr>
      <tr v-if="this.person && this.person.manager">
        <td align="right">Manager</td>
        <td><PersonDisplay :email="this.person.manager" mode="minitag"/></td>
      </tr>
      <tr v-if="this.person && this.person.start_date">
        <td align="right">Start Date</td>
        <td>{{this.person.start_date}}</td>
      </tr>
      <tr>
        <td align="right" valign="top">Badges</td>
        <td>
          <span v-for="badge in badges" :key="badge.id">
            <BadgeDisplay :badge="badge"/>
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getPerson } from "@/google"
import { setError } from "@/error"
import PersonDisplay from '@/components/PersonDisplay.vue'
import BadgeDisplay from '@/components/BadgeDisplay.vue'
import { collection, query, getDocs, where } from 'firebase/firestore';

export default {
  name: 'PersonView',
  inject: [
    'authenticated',
    'profile_email',
    'firebase_db'
  ],
  components: {
    PersonDisplay,
    BadgeDisplay
  },
  data: function () {
    return {
      person_email: undefined,
      person: undefined,
      person_google_profile: undefined,
      person_github_profile: undefined,
      badges: []
    }
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.load()
      }
    }
  },
  created: function () {
    if (this.authenticated) {
      console.log('authenticated')
      this.load()
    }
    this.$watch(
      () => this.$route.params, () => {
        this.load();
      }
    )
  },
  methods: {
    load: async function () {
      console.log("PersonView load " + this.$route.params.email);
      if (this.$route.params.email === undefined) {
        this.person_email = this.profile_email
      } else {
        this.person_email = this.$route.params.email
      }

      // Get all badges for this person.
      const q = query(collection(this.firebase_db, "badges"), where("members", "array-contains", this.person_email))
      getDocs(q).then(querySnapshot => {
        this.badges = []
        querySnapshot.forEach((doc) => {
          this.badges.push({
            description: doc.data().description,
            icon_base64: doc.data().icon_base64,
            id: doc.id
          })
        })
      })

      this.person = await getPerson(localStorage['oauth_access_token'], this.person_email)
      if (this.person === undefined) {
        setError(this.person_email + " no longer has an account or is an invalid username")
        return
      }

      this.person_google_profile = "https://contacts.google.com/person/"
        + this.person.id;
      this.person_github_profile = "https://git.r2air.com/"
        + this.person_email.substring(0, this.person_email.indexOf('@'));
    }
  }
}
</script>
