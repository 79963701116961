import { createRouter, createWebHistory } from 'vue-router'
import PersonView from '../views/PersonView.vue'
import PeopleView from '../views/PeopleView.vue'
import BadgesView from '../views/BadgesView.vue'
import BadgeView from '../views/BadgeView.vue'
import AddBadgeView from '../views/AddBadgeView.vue'

const routes = [
  {
    path: '/',
    component: PersonView
  },
  {
    path: '/person/:email',
    name: 'person',
    component: PersonView
  },
  {
    path: '/people',
    name: 'people',
    component: PeopleView
  },
  {
    path: '/badges',
    name: 'badges',
    component: BadgesView
  },
  {
    path: '/badge/:id',
    name: 'badge',
    component: BadgeView
  },
  {
    path: '/addbadge',
    name: 'addbadge',
    component: AddBadgeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
