<style scoped>
</style>

<template>
  <span :v-if="authenticated">
    {{name}}
  </span>
</template>

<script>
import { getPerson } from "@/google"

export default {
  name: 'PersonNameDisplay',
  inject: [
    'authenticated',
    'firebase_db'
  ],
  props: {
    email: String
  },
  data: function () {
    return {
      name: undefined
    }
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.load()
      }
    }
  },
  created: function () {
    if (this.authenticated) {
      this.load()
    }
  },
  methods: {
    load: async function () {
      var person = await getPerson(localStorage['oauth_access_token'], this.email)
      if (person === undefined) {
        this.name = ""
      } else {
        this.name = person.name
      }
    }
  }
}
</script>
