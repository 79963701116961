<style scoped>
.fa-plus-circle {
  font-size: 14px;
}

.click {
  cursor: pointer;
}

.validation {
  color: red;
  padding: 2px;
}
</style>

<template>
  <span v-on:click="activate" class="click">
    <i class="fa fa-plus-circle"></i>
    {{message}}
  </span>
  <div v-if="popup" class="popup">
    <select v-model="method">
      <option value="bulk">Bulk: Enter names manually</option>
      <option value="milestone">Milestone: Add everyone with a start date at or before date</option>
    </select>
    <br>
    <div v-if="method == 'bulk'">
      Enter one email address per line:
    </div>
    <div v-if="method == 'milestone'">
      Input a date in the form YYYY-MM-DD:<br>
      <input type="text" v-model="date"/>
    </div>

    <textarea cols="50" rows="10" v-model="names" :disabled="method == 'milestone'"></textarea>
    <button v-on:click="submit">Submit</button>
    <span v-if="update">
      <i class="fa fa-solid fa-cog fa-spin"></i>
    </span>
    <div class="validation" v-if="name_validation">{{name_validation}}</div>
    
  </div>
</template>

<script>
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getPerson, loadPeople, getPeopleStartDateOnOrBefore } from "@/google"
import { setError } from "@/error"

export default {
  name: 'AddPeople',
  inject: [
    'authenticated',
    'firebase_db'
  ],
  props: {
    type: String,
    badge: String,
    message: String
  },
  components: {
  },
  data: function () {
    return {
      names: undefined,
      popup: false,
      method: "bulk",
      date: undefined,
      name_validation: undefined,
      update: false
    }
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.load()
      }
    },
    names() {
      this.validateNames()
    },
    date() {
      this.validateDate()
    }
  },
  created: function () {
    if (this.authenticated) {
      this.load()
    }
  },
  methods: {
    load: async function () {
      await loadPeople(localStorage['oauth_access_token'])
    },
    activate: function () {
      this.popup = !this.popup
    },
    validateDate: async function() {
      this.name_validation = undefined
      var day = Date.parse(this.date + "T00:00:00")

      if (isNaN(day)) {
        this.name_validation = "Date must be in the form YYYY-MM-DD"
        return;
      }

      var people = await getPeopleStartDateOnOrBefore(localStorage['oauth_access_token'], day)
      console.log(people)

      this.names = ""
      for (var i in people) {
        var person = people[i]

        this.names += person + "\n"
      }

      console.log(this.names)
    },
    validateNames: async function() {
      this.name_validation = ""
      var lines = this.names.split('\n')
      
      for (var i in lines) {
        var name = lines[i]
        if (name == "") {
          continue
        }

        var person = await getPerson(localStorage['oauth_access_token'], name)

        if (!person) {
          this.name_validation += name + " not found\n"
        }
      }

      if (this.name_validation == "") {
        this.name_validation = undefined
      }
    },
    submit: async function() {
      await this.validateNames()

      if (this.name_validation) {
        return
      }

      var lines = this.names.split('\n')
      var names = []
      for (var i in lines) {
        var name = lines[i]
        if (name == "") {
          continue
        }
        names.push(name)
      }

      this.update = true
      var updateRecord = {}
      updateRecord[this.type] = arrayUnion(...names)

      updateDoc(doc(this.firebase_db, "badges", this.badge), updateRecord).then(() => {
        this.update = false
        this.$router.go('/badge/' + this.badge)
      }).catch((error) => {
        setError(error)
      })
    }
  }
}
</script>
