<style scoped>
@media only screen and (min-width: 700px) {
  .maintable td {
    min-width: 150px;
    padding: 5px;
  }
}

.maintable td {
  
}

.listtable td {
  min-width: 64px;
}

.fa {
  font-size: 20px;
}

</style>

<template>
  <div v-if="authenticated" class="about">
    <table class="maintable">
      <tr>
        <td></td>
        <td width="100%">
          <div style="margin-bottom: 20px">
            <h1 style="display: inline">All Badges</h1>
            <i class="fa fa-list" style="float: right; cursor: pointer" v-on:click="listToggle"></i>
          </div>
          <i v-if="!badges.length" class="fa fa-cog fa-spin"></i>
          <div v-if="!listdisplay">
            <span v-for="badge in badges" :key="badge.id">
              <BadgeDisplay :badge="badge" :manager="badge.manager"/>
            </span>
          </div>
          <div v-if="listdisplay">
            <table class="listtable">
              <tr v-for="badge in badges" :key="badge.id">
                <td class="listicon">
                  <BadgeDisplay :badge="badge" :manager="badge.manager"/>
                </td>
                <td>
                  <router-link :to="{name: 'badge', params: {id: badge.id}}">
                    {{badge.id}}
                  </router-link>
                </td>
                <td class="listitem">
                  <router-link :to="{name: 'badge', params: {id: badge.id}}">
                    {{badge.description}}
                  </router-link>
                </td>
              </tr>
            </table>
          </div>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>
        </td>
        <td>
          <br><br>
          <router-link to="/addbadge">
            <i class="fa fa-plus-circle"></i>
            Add Badge
          </router-link>
        </td>
        <td></td>
      </tr>
    </table>
  </div>
</template>


<script>
import { collection, query, getDocs } from 'firebase/firestore';
import BadgeDisplay from '@/components/BadgeDisplay.vue'

export default {
  name: 'BadgesView',
  inject: [
    'authenticated',
    'firebase_db',
    'profile_email'
  ],
  data: function () {
    return {
      badges: [],
      listdisplay: false
    }
  },
  components: {
    BadgeDisplay
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.load()
      }
    }
  },
  created: function () {
    if (this.authenticated) {
      this.load()
    }
  },
  methods: {
    load: function () {
      // TODO: This is dumb that listing all of the badges downloads the entire database.
      // Firebase doesn't have an API that lets you do a shallow query unless you go through the REST
      // interface. Future action to rewrite this using the REST interface.
      // https://firebase.google.com/docs/database/rest/retrieve-data#shallow
      const q = query(collection(this.firebase_db, "badges"))
      getDocs(q).then(querySnapshot => {
        this.badges = []
        querySnapshot.forEach((doc) => {
          var badge = doc.data()
          if (doc.data().managers && doc.data().managers.includes(this.profile_email)) {
            badge.manager = true
          } else  {
            badge.manager = false
          }
          badge.id = doc.id
          this.badges.push(badge)
        })
      })
    },
    listToggle: function() {
      this.listdisplay = !this.listdisplay
    }
  }
}
</script>
