<style scoped>
td {
  padding: 5px;
  min-width: 150px;
}

.validation {
  color: red;
}
</style>

<template>
  <div v-if="authenticated">
    <table>
      <tr>
        <td>
        </td>
        <td><h1>Add Badge</h1></td>
      </tr>
      <tr>
        <td align=right>Name</td>
        <td>
          <input type="text" v-model="name" placeholder="badge-name"/>
          <div class="validation">{{name_validation}}</div>
        </td>
      </tr>
      <tr>
        <td align=right>Icon</td>
        <td><IconUpload @update="onIconUpdate"/></td>
      </tr>
      <tr>
        <td align=right>Description</td>
        <td>
          <input type="text" v-model="description" placeholder="badge-description"/>
          <div class="validation" v-if="!description">Description required</div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <span v-if="name && !name_validation && icon_data && description">
            <button :disabled="submit_disable" v-on:click="submit">Submit</button>
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { setError } from "@/error"
import { collection, getDoc, doc, setDoc } from "firebase/firestore";
import IconUpload from '@/components/IconUpload.vue'

export default {
  name: 'AddBadgeView',
  inject: [
    'authenticated',
    'profile_email',
    'firebase_db'
  ],
  data: function () {
    return {
      name: undefined,
      name_validation: undefined,
      submit_disable: false,
      icon_data: undefined,
      description: undefined
    }
  },
  components: {
    IconUpload
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.load()
      }
    },
    name() {
      this.validateName()
    }
  },
  created: function () {
    console.log("AddBadgeView created " + this.authenticated)
    if (this.authenticated) {
      this.load()
    }
  },
  methods: {
    load: function () {
      
    },
    validateName: async function() {
      if (!this.name) {
        this.name_validation = 'Badge name cannot be empty'
        return false;
      }
      var nameRegex = /^[a-z0-9-]+$/g
      if (!this.name.match(nameRegex)) {
        this.name_validation = 'Invalid badge name (/[a-z-]+/)'
        return false
      } else {

        const badgesRef = collection(this.firebase_db, "badges");
        const q = doc(badgesRef, this.name);

        var docExists = await getDoc(q).then(doc => {
          if (doc.data() === undefined) {
            // Badge does not exist yet
            return false
          } else {
            return true
          }

        }).catch(error => {
          setError(error)
        });

        if (!docExists) {
          this.name_validation = undefined
          return true
        } else {
          this.name_validation = 'Badge with this name already exists'
          return false
        }
        
      }
    },
    validate: async function() {
      // Validate inputs
      var name_validated = await this.validateName()

      if (!name_validated) {
        return false
      } else if (!this.icon_data && this.icon_validation) {
        return false
      } else if (!this.icon_data) {
        this.icon_validation = 'Icon required'
        return false
      }

      return true
    },
    onIconUpdate: function(data) {
      this.icon_data = data
    },
    submit: async function() {
      if (!this.authenticated) {
        setError('Authentication failure')
        return
      }

      var validated = await this.validate()
      if (!validated) {
        return
      }
      
      // Disable submit button
      this.submit_disable = true

      setDoc(doc(this.firebase_db, "badges", this.name), {
        description: this.description,
        managers: [this.profile_email],
        icon_base64: this.icon_data
      }).then(() => {
        // Success, forward to the badge page.
        this.$router.push('/badge/' + this.name)
      }).catch((error) => {
        setError(error)
      })
      
    }
  }
}
</script>
